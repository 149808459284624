:root {
    --primary-color: #000000;
    /* #0b2401;  */
    --primary-blue-color:#007bff;
    --card-bg-color:#FEF2EC;
    --button-color:#A03B08;
    --dark-color:#000000;
    /* #141816; */
    --army-green-color:#353f34;
    --light-army-green-color:#EEF1EE;


    --main-card-bg-color:#3F8EEE;

    --log-blue-color:#0d4991;
    --log-yellow-color:#ffb63a;
    --log-red-color:#c91940;
    --log-green-color:#1b7132;

    --text-yellow-color:#f6c23e;

}

.log-red-color{color: var(--log-red-color);}
.white-text{color: white;}
.green-text{color: var(--log-green-color) !important;}
.primary-yellow-color-text{color: var(--text-yellow-color) !important;}
.black-color-text{color:black !important;}
.primary-text
{
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
}
.booking-img-left{
    width: 250px;
    height: 250px;
}
.reservation-img-left{
    width: 200px;
    height: 200px;
}
.body-container{
    background: #f6f9ff; 
    border-radius: 10px;
    margin: auto;
    width: 95%;
    margin-bottom: 30px;
}
.nav-header-container{
    margin-top: 10px;
    background: var(--dark-color)!important;  
    height: 80px;
    /* width: 100%; */
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

}
.footer-container{
    background:var(--dark-color); 
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.footer-cards{
    background:var(--dark-color) !important; 
}
.log-green-text{color: var(--log-green-color); font-weight: bold;}

.align-items-center {
    align-items: center !important;}
.strong-text{padding-left: 10px;}
.custom-primary-color{color:#508e49 !important}
.custom-secondary-color{color:#074900 !important}
.custom-bg-primary-color{background-color:#508e49 !important}
.inner-body-container{margin-left: 10px; margin-right: 10px; margin-top: 15px; }

/* input[type="text"] {background-color: var(--army-green-color);} */

.form-inputs
{
    /* border: none;
     */border-radius: 5px !important;
    border: 2px solid var(--army-green-color) !important;


}
.search-form-inputs
{
    margin-top: -20px;
    width: 80%;
    border-radius: 5px !important;
    border: 2px solid var(--army-green-color) !important;


}
.primary-text
{
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
}
.form-inputs:focus,.search-form-inputs:focus {
    border: 3px solid var(--log-yellow-color) !important;
}

.nav-links-text,#transport-nav-dropdown,#destination-nav-dropdown,#tours-nav-dropdown {
    color: white;
    font-size: 15px;
}
.nav-links-text:hover,#destination-nav-dropdown:hover,#tours-nav-dropdown:hover,
#transport-nav-dropdown:hover{
    color: var(--text-yellow-color);
    text-decoration: underline;
    text-transform: uppercase;
}
.vertical-menu-links
{
    margin-top: 10px;
    /* background: black !important;   */
    /* height: 40px; */
    /* width: 100%; */
    border-top-right-radius: 10px;
}
.logo-img{
    width: 80px;
    height: 80px;
}


.logo-img-social-media {
    width: 40px;
    height: 40px;
    transition: filter 0.3s ease-in-out;
}

  .logo-img-social-media:hover {
    filter: invert(33%) sepia(83%) saturate(747%) hue-rotate(360deg); /* Change color */
  }
.safari-right-float{
    margin-top: -50px;
    float: right;
}
.book-now-btn{
    margin-right: 100px;
    margin-top: -10px;
    width: 180px;
    
}
.single-line {
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden;    /* Hide overflowing text */
    text-overflow: ellipsis; /* Add "..." at the end of the line */
    width: 100%; /* Set a width or max-width to define the line length */
    display: block; /* Ensures it behaves like a block-level element */
}
.three-line {
    display: -webkit-box; /* For truncation */
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical; /* Specifies block direction */
    overflow: hidden; /* Hides overflowing text */
    text-overflow: ellipsis; /* Adds "..." for overflowing text */
    max-height: 4.5em; /* Ensure consistent height for 3 lines */
    line-height: 1.5em; /* Set line height to calculate total height */
}
.call-now-btn{
    margin-right: 20px; 
    margin-top: -10px;

    width: 180px;
    
}
.carousel-img{
    height: 600px;
    /* border-radius: 20px !important; */

}
.custom-card{
    border-radius: 20px !important;
    background-color: var(--army-green-color) !important;
}
.custom-card-no-color{border-radius: 20px !important;}
.custom-card-imgs{
    border-radius: 20px !important;
    height: 250px !important;
}

.dashboard-card-title{
    color: var(--text-yellow-color) !important;
    font-weight: bold;
    font-size: 20px; 
}

.dashboard-card-description{
    color: var(--text-yellow-color) !important;
}
.footer-links-text
{
    color: white;
    font-size: 18px;
}
.tour-package-image{
    /* width: 280px;*/
    height: 280px; 
    border-radius: 20px !important;
    margin-bottom: 5px;
}
.tour-package-custom-card{
    border-radius: 20px !important;
    background: var(--light-army-green-color) !important;
}
.tour-package-book-package-btn{
    float: right;
}
.email-btn{
    margin-top: 10px;
    width: 100%;
}

.safaris-details-card-img{
    border-radius: 20px !important;
    height: 600px;
}


.safari-booking-grid-container-columns{
    display: grid;
    grid-template-columns: auto  auto auto;
}
.my-grid-container-2-columns
{
    display: grid;
    grid-template-columns: auto auto;
}

.my-grid-container-3-columns
{
    display: grid;
    grid-template-columns: auto auto auto;
}

.my-grid-container-4-columns
{
    display: grid;
    grid-template-columns: auto auto auto auto;
}
.my-grid-container-5-columns
{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
}
.my-grid-container-5-columns
{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
}

.my-grid-item 
{
    padding: 5px;
    font-size: 5px;
}



/* ======================== Search Filter ====================================== */

.search-filter-headings{
    width: 500px;
    background-color: var(--army-green-color);
    border-top-left-radius : 20px;
    border-top-right-radius : 20px;
    border-bottom: 2px solid black !important;
    border-top:5px solid var(--log-yellow-color) !important ;
    

}
.grid-separator-right{
    border-right:2px solid var(--log-yellow-color) !important ;
}
.search-filter-grid-item{
    padding: 10px;

}
.search-filter-btn{
    margin-top: 2px;
    width: 180px;
}
.search-filter-selection-inputs-area{
    width: 800px;
    background-color: var(--army-green-color);
    border-radius: 20px;
    border-bottom: 6px solid black !important;
}
.search-filter-selection-inputs-area-grid-item{
    padding: 0px;
    /* height: 10px; */
}
.search-filter-labels:hover{
    border-left:5px solid var(--log-yellow-color) !important ;
    border-right:5px solid var(--log-yellow-color) !important ;
}
#search-select-a-tour-id,#search-select-a-destination-id,#search-select-a-days-id,
.search-label-text{
    color: var(--log-yellow-color);
    font-weight: bold; 
}
.selection-grid-separator-right{
    border-right:2px solid black !important ;
}
/* Basic button styling */
.custom-button-span {
    color: white; 
    padding:  2px; 
    font-size: 16px; 
    font-weight: bold; 
    cursor: pointer; 
}

/* active effect */
.custom-button-span.active {color: var(--log-yellow-color);}

.custom-button {
    margin-left: 5px;
    background-color: var(--primary-blue-color); 
    color: #fff; 
    width: 100%;
    border: none; 
    padding: 10px; 
    font-size: 16px; 
    font-weight: bold; 
    border-top-right-radius: 20px; 
    border-bottom-right-radius: 20px; 
    cursor: pointer;
}
.custom-button-2 {
    margin-left: 5px;
    background-color: var(--primary-blue-color); 
    color: #fff; 
    width: 100%;
    height: 100%;
    border: none; 
    padding: 10px; 
    font-size: 16px; 
    font-weight: bold; 
    border-top-right-radius: 20px; 
    border-bottom-right-radius: 20px; 
    cursor: pointer;
}
  /* Hover effect */
.custom-button:hover,.custom-button-2:hover {
background-color: var(--log-yellow-color);
}
.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    /* bottom: 40px; */
    top: 18px;
    right: 55px;
    /* background-color: #25d366; */
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    z-index: 1000; 
}

.whatsapp_float img {
width: 100%;
height: 100%;
border-radius: 50%;
}



.col-auto {
flex: 0 0 auto;
width: auto;
max-width: 100%;
}
.border-left-primary {
border-left: 5px solid #4e73df !important;
border-right: 5px solid #4e73df !important;
border-top: 1px solid #4e73df !important;
border-bottom: 1px solid #4e73df !important;
cursor: pointer;

}

.border-bottom-primary {
border-bottom: 5px solid #4e73df !important;
}

.border-left-secondary {
border-left: 5px solid #858796 !important;
}

.border-bottom-secondary {
border-bottom: 5px solid #858796 !important;
}

.border-left-success {
border-left: 5px solid #1cc88a !important;
border-right: 5px solid #1cc88a  !important;
border-top: 1px solid #1cc88a  !important;
border-bottom: 1px solid #1cc88a  !important;
cursor: pointer;
}

.border-bottom-success {
border-bottom: 5px solid #1cc88a !important;
}

.border-left-info {
border-left: 5px solid #36b9cc !important;
border-right: 5px solid #36b9cc   !important;
border-top: 1px solid #36b9cc   !important;
border-bottom: 1px solid #36b9cc   !important;
cursor: pointer;
}

.border-bottom-info {
border-bottom: 5px solid #36b9cc !important;
}

.border-left-warning {
border-left: 5px solid #f6c23e !important;
border-right: 5px solid #f6c23e   !important;
border-top: 1px solid #f6c23e   !important;
border-bottom: 1px solid #f6c23e   !important;
cursor: pointer;
}

.border-bottom-warning {
border-bottom: 5px solid #f6c23e !important;
}

.border-left-danger {
border-left: 5px solid #e74a3b !important;
}

.border-bottom-danger {
border-bottom: 5px solid #e74a3b !important;
}

.border-left-light {
border-left: 5px solid #f8f9fc !important;
}

.border-bottom-light {
border-bottom: 5px solid #f8f9fc !important;
}

.border-left-dark {
border-left: 5px solid #5a5c69 !important;
}

.border-bottom-dark {
border-bottom: 5px solid #5a5c69 !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .nav-header-container{height: 280px;}
    .safari-booking-grid-container-columns{grid-template-columns: auto;}
    #horizontal-menu{display: none;}
    #vertical-menu{display: block;}
    .book-now-btn{
        margin-top: 5px;
        margin-right: 0px; 

    }
    .call-now-btn{
        margin-top: 5px;
        margin-right: 0px; 
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    #vertical-menu{display: none;}
    #horizontal-menu{display: block;}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    /* #vertical-menu{display: none;}
    #horizontal-menu{display: block;} */

} 

/* Large devices (laptops/desktops, 995px and up) */
@media only screen and (min-width: 995px) {
.example {background: orange;}
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
.example {background: pink;}
}